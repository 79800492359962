import {
  createContext,
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { useHistory } from 'react-router-dom';
import {
  useFormik,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import { TPOSettingsInitialValues } from 'initialValues/OrganizationInitialValues';
import { TPOSettingsValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import {
  defaultConfigSelector,
  editOrganizationTPOSettings,
  organizationConfigSelector,
  organizationSelector,
  organizationsPendingConfigsSelector,
  saveOrganizationTPOSettingsBanking,
} from 'store/slices/organizations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  defaultConfigMapper,
  organizationConfigMapper,
  saveOrganizationTPOSettingsMapper,
  saveOrganizationTPOSettingsBankingMapper,
} from 'dataMappers/organizationsDataMappers';
import { TPOSettingsValuesType } from 'types/OrganizationTypes';
import AppCheckbox from 'components/Form/AppCheckbox';
import Loader from 'components/Loader';
import {
  StyleObject,
  useStyletron,
} from 'styletron-react';
import useIsFormChanged from 'hooks/useIsFormChanged';
import AppSelect from 'components/Form/AppSelect';
import AppTimePicker from 'components/Form/AppTimePicker';
import AppInput from 'components/Form/AppInput';
import { currencyCodeSelector } from 'store/slices/application';
import { notificationSelector } from 'store/slices/notification';
import { errorSelector } from 'store/slices/error';

export const panelStyles = { display: 'flex', 'justify-content': 'space-between' };

export const TPOSettingsFormContext = createContext({} as FormikState<TPOSettingsValuesType> & FormikHelpers<TPOSettingsValuesType> & FormikHandlers);

const containerStyles = {
  position: 'relative',
} as StyleObject;

const OrganizationFormTPOSection = () => {
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const currencyCode = useAppSelector(currencyCodeSelector);
  const pending = useAppSelector(organizationsPendingConfigsSelector);
  const organization = useAppSelector(organizationSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const error = useAppSelector(errorSelector);

  const initialValues = {
    ...TPOSettingsInitialValues,
    ...(defaultConfig && defaultConfigMapper(defaultConfig)),
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
  };

  const onSubmit = (
    values: TPOSettingsValuesType,
  ) => {
    dispatch(editOrganizationTPOSettings({
      organizationID: organization?.id,
      configs: saveOrganizationTPOSettingsMapper(values),
    }))
      .unwrap()
      .then(() => {
        dispatch(saveOrganizationTPOSettingsBanking({
          organizationID: organization?.id,
          data: saveOrganizationTPOSettingsBankingMapper(values),
        }));
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialTouched: {
      tpoFeeEmployeePerPeriodValue: true,
    },
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  useEffect(() => {
    if (organizationConfig) {
      setDefaultValues(initialValues);
      setValues(initialValues);
    }
  }, [organizationConfig]);

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  const payoutSchedules = [
    { value: 'API', label: t('organizations:tpo.payoutSchedule.manual') },
    { value: 'SCHEDULED', label: t('organizations:tpo.payoutSchedule.automatic') },
    { value: 'API_OR_SCHEDULED', label: t('organizations:tpo.payoutSchedule.automaticWithOverride') },
  ];

  return (
    <TPOSettingsFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={pending} />

        <form onSubmit={handleSubmit}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell
              span={12}
              align={ALIGNMENT.start}
            >
              <h3>{t('organizations:program.enabled')}</h3>
            </Cell>

            <AppCheckbox
              name="tpoEnabled"
              label={t('organizations:program.labelEnabled')}
              cellSpan={12}
              context={TPOSettingsFormContext}
            />
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell span={[12, 4]}>
              <h3>{t('organizations:tpoPercentHeading')}</h3>
              <Block marginLeft="-16px">
                <AppInput
                  showStar
                  name="tpoPercent"
                  inputProps={{
                    id: 'tpoPercent',
                    autoComplete: 'off',
                    min: 0,
                    max: 100,
                    type: 'number',
                    endEnhancer: '%',
                    disabled: !values.tpoEnabled,
                  }}
                  label={t('organizations:tpoPercentLabel')}
                  cellSpan={[12]}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
            <Cell span={[12, 4]}>
              <h3>{t('organizations:tpo.payout.settings')}</h3>
              <Block marginLeft="-16px">
                <AppSelect
                  showStar
                  name="tpoDailyPayoutSchedule"
                  label={t('organizations:tpo.payout.settings.daily.schedule')}
                  cellSpan={[12]}
                  context={TPOSettingsFormContext}
                  options={payoutSchedules}
                  selectProps={{
                    clearable: false,
                    disabled: !values.tpoEnabled,
                  }}
                />
                <AppTimePicker
                  showStar
                  name="tpoDailyPayoutTime"
                  label={t('organizations:tpo.payout.settings.daily.time')}
                  cellSpan={[12]}
                  timePickerProps={{ nullable: true, disabled: values.tpoDailyPayoutSchedule[0].value === 'API' || !values.tpoEnabled }}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell span={[12]}>
              <h3>{t('organizations:tpo.payment.options')}</h3>
            </Cell>
            <Cell span={[12]}>
              <Block marginLeft="-20px">
                <AppCheckbox
                  name="tpoFeesPaidByEmployer"
                  label={t('organizations:tpoFeesPaidByEmployerCheckboxLabel')}
                  cellSpan={[12, 3, 3]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  disabled={!values.tpoEnabled}
                />
                <AppCheckbox
                  name="tpoFeeEmployeePerPeriod"
                  label={t('organizations:tpoFeeEmployeePerPeriodCheckboxLabel')}
                  cellSpan={[12, 3, 3]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  checkboxProps={{
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        tpoFeeEmployeePerPeriod: isChecked,
                        tpoFeeEmployeePerPeriodValue: isChecked ? values.tpoFeeEmployeePerPeriodValue : 0,
                      });
                    },
                    disabled: !values.tpoEnabled,
                  }}
                />
                <AppInput
                  showStar
                  name="tpoFeeEmployeePerPeriodValue"
                  inputProps={{
                    id: 'tpoFeeEmployeePerPeriodValue',
                    autoComplete: 'off',
                    min: 0,
                    type: 'number',
                    step: 'any' as any,
                    endEnhancer: currencyCode,
                    disabled: !values.tpoEnabled || !values.tpoFeeEmployeePerPeriod,
                  }}
                  label={t('organizations:tpoFeeEmployeePerPeriodValueLabel')}
                  cellSpan={[12, 3, 3]}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell span={[12, 6, 3]}>
              <Block marginLeft="-20px">
                <AppCheckbox
                  name="tpoFuegoCardPaymentEnabled"
                  label={t('organizations:tpoFuegoCardPaymentCheckboxLabel')}
                  cellSpan={[12]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  disabled={!values.tpoEnabled}
                  checkboxProps={{
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        tpoFuegoCardPaymentEnabled: isChecked,
                        tpoFuegoCardPaymentValue: isChecked ? values.tpoFuegoCardPaymentValue : 0,
                      });
                    },
                  }}
                />
                <AppInput
                  showStar
                  name="tpoFuegoCardPaymentValue"
                  inputProps={{
                    id: 'tpoFuegoCardPaymentValue',
                    autoComplete: 'off',
                    min: 0,
                    type: 'number',
                    step: 'any' as any,
                    endEnhancer: currencyCode,
                    disabled: !values.tpoEnabled || !values.tpoFuegoCardPaymentEnabled,
                  }}
                  label={t('organizations:tpoFuegoCardPaymentValueLabel')}
                  cellSpan={[12]}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
            <Cell span={[12, 6, 3]}>
              <Block marginLeft="-20px">
                <AppCheckbox
                  name="tpoAchPaymentFeeEnabled"
                  label={t('organizations:tpoAchPaymentFeeCheckboxLabel')}
                  cellSpan={[12]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  disabled={!values.tpoEnabled}
                  checkboxProps={{
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        tpoAchPaymentFeeEnabled: isChecked,
                        tpoAchPaymentFeeValue: isChecked ? values.tpoAchPaymentFeeValue : 0,
                      });
                    },
                  }}
                />
                <AppInput
                  showStar
                  name="tpoAchPaymentFeeValue"
                  inputProps={{
                    id: 'tpoAchPaymentFeeValue',
                    autoComplete: 'off',
                    min: 0,
                    type: 'number',
                    step: 'any' as any,
                    endEnhancer: currencyCode,
                    disabled: !values.tpoEnabled || !values.tpoAchPaymentFeeEnabled,
                  }}
                  label={t('organizations:tpoAchPaymentFeeValueLabel')}
                  cellSpan={[12]}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
            <Cell span={[12, 6, 3]}>
              <Block marginLeft="-20px">
                <AppCheckbox
                  name="tpoSameDayAchPaymentFeeEnabled"
                  label={t('organizations:tpoSameDayAchPaymentFeeCheckboxLabel')}
                  cellSpan={[12]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  disabled={!values.tpoEnabled}
                  checkboxProps={{
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        tpoSameDayAchPaymentFeeEnabled: isChecked,
                        tpoSameDayAchPaymentFeeValue: isChecked ? values.tpoSameDayAchPaymentFeeValue : 0,
                      });
                    },
                  }}
                />
                <AppInput
                  showStar
                  name="tpoSameDayAchPaymentFeeValue"
                  inputProps={{
                    id: 'tpoSameDayAchPaymentFeeValue',
                    autoComplete: 'off',
                    min: 0,
                    type: 'number',
                    step: 'any' as any,
                    endEnhancer: currencyCode,
                    disabled: !values.tpoEnabled || !values.tpoSameDayAchPaymentFeeEnabled,
                  }}
                  label={t('organizations:tpoSameDayAchPaymentFeeValueLabel')}
                  cellSpan={[12]}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
            <Cell span={[12, 6, 3]}>
              <Block marginLeft="-20px">
                <AppCheckbox
                  name="tpoPushToCardPaymentFeeEnabled"
                  label={t('organizations:tpoPushToCardPaymentFeeCheckboxLabel')}
                  cellSpan={[12]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  disabled={!values.tpoEnabled}
                  checkboxProps={{
                    onChange: (option) => {
                      const isChecked = option.currentTarget.checked;
                      setValues({
                        ...values,
                        tpoPushToCardPaymentFeeEnabled: isChecked,
                        tpoPushToCardPaymentFeeValue: isChecked ? values.tpoPushToCardPaymentFeeValue : 0,
                      });
                    },
                  }}
                />
                <AppInput
                  showStar
                  name="tpoPushToCardPaymentFeeValue"
                  inputProps={{
                    id: 'tpoPushToCardPaymentFeeValue',
                    autoComplete: 'off',
                    min: 0,
                    type: 'number',
                    step: 'any' as any,
                    endEnhancer: currencyCode,
                    disabled: !values.tpoEnabled || !values.tpoPushToCardPaymentFeeEnabled,
                  }}
                  label={t('organizations:tpoPushToCardPaymentFeeValueLabel')}
                  cellSpan={[12]}
                  context={TPOSettingsFormContext}
                />
              </Block>
            </Cell>
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <Cell span={[12]}>
              <h3>{t('organizations:tpo.payment.batch.management')}</h3>
            </Cell>
            <Cell span={[12]}>
              <Block marginLeft="-20px">
                <AppCheckbox
                  name="tpoGroupBatchesByBankAccountOrPayGroup"
                  label={t('organizations:tpoGroupBatchesValueLabel')}
                  cellSpan={[12, 3, 3]}
                  cellAlign={ALIGNMENT.center}
                  context={TPOSettingsFormContext}
                  disabled={!values.tpoEnabled}
                />
                {values.tpoGroupBatchesByBankAccountOrPayGroup
                && (
                  <AppCheckbox
                    name="tpoSendAutomaticAchRequests"
                    label={t('organizations:tpoSendAutomaticAchRequestsValueLabel')}
                    cellSpan={[12, 3, 3]}
                    cellAlign={ALIGNMENT.center}
                    context={TPOSettingsFormContext}
                    disabled={!values.tpoEnabled}
                  />
                )}
              </Block>
            </Cell>
          </Grid>
          <hr />

          <Block marginTop="24px">
            <Grid
              align={ALIGNMENT.start}
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormTPOSection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>

                  <Button
                    type="submit"
                    kind={KIND.primary}
                    disabled={isSubmitting || !isFormChanged || !isValid}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormTPOSection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
    </TPOSettingsFormContext.Provider>
  );
};

export default memo(OrganizationFormTPOSection);
