import { SIZE } from 'baseui/input';
import { OnChangeParams, Select, Value } from 'baseui/select';
import {
  memo, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import CellFormControl from 'components/CellFormControl';
import { ALIGNMENT, Grid } from 'baseui/layout-grid';
import { DatePicker } from 'baseui/datepicker';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeActionRequired,
  fetchPayPeriodsWithActions,
  payPeriodsActionRequiredSelector,
  payPeriodsStatusSelector,
  payPeriodsValueDateSelector,
  setStatus,
  setValueDate,
} from 'store/slices/dashboard';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { format } from 'date-fns';
import { PayPeriodStatusEnum } from 'types/PayrollPeriodTypes';

const PayPeriodStatusFilters = () => {
  const { t } = useTranslation(['common', 'dashboard', 'dateFormats']);
  const valueDateState = useAppSelector(payPeriodsValueDateSelector);
  const actionRequiredState = useAppSelector(payPeriodsActionRequiredSelector);
  const statusState = useAppSelector(payPeriodsStatusSelector);
  const prevDate = useRef<OptionalDateOrDateArrayType>([]);
  const dispatch = useAppDispatch();
  const organization = useAppSelector(loggedOrganizationSelector);

  const dateFormat = t('dateFormats:date-picker.reverse');
  const displayFormat = t('dateFormats:standard');
  const datePickerFormat = t('dateFormats:date-picker.standard');

  const actionRequiredOptions = [
    {
      id: 0,
      value: undefined,
      name: t('common:all'),
    },
    {
      id: 1,
      value: true,
      name: t('dashboard:dashboard.actionRequiredOptions.actionRequired'),
    },
    {
      id: 2,
      value: false,
      name: t('dashboard:dashboard.actionRequiredOptions.noActionRequired'),
    },
  ];

  const statusOptions = [
    {
      id: 0,
      value: '',
      name: t('common:all'),
    },
    {
      id: 1,
      value: PayPeriodStatusEnum.FUTURE,
      name: t('dashboard:dashboard.statusOptions.future'),
    },
    {
      id: 2,
      value: PayPeriodStatusEnum.OPEN,
      name: t('dashboard:dashboard.statusOptions.open'),
    },
    {
      id: 3,
      value: PayPeriodStatusEnum.LOCKED,
      name: t('dashboard:dashboard.statusOptions.locked'),
    },
    {
      id: 4,
      value: PayPeriodStatusEnum.CLOSED,
      name: t('dashboard:dashboard.statusOptions.closed'),
    },
  ];

  const fetchPayPeriods = ({
    status,
    actionRequired,
    valueDate,
  }: {
    status: Value;
    actionRequired: Value;
    valueDate: OptionalDateOrDateArrayType;
  }) => {
    organization
      && dispatch(
        fetchPayPeriodsWithActions({
          organizationId: organization.id,
          pageNumber: 1,
          pageSize: 10,
          from:
            Array.isArray(valueDate) && valueDate?.[0]
              ? format(valueDate[0], dateFormat)
              : '',
          to:
            Array.isArray(valueDate) && valueDate?.[1]
              ? format(valueDate[1], dateFormat)
              : '',
          statuses: status?.[0]?.value,
          actionRequired: actionRequired?.[0]?.value,
        }),
      );
  };

  const handleDateChange = ({
    date,
  }: {
    date: OptionalDateOrDateArrayType;
  }) => {
    if (Array.isArray(date)) {
      dispatch(setValueDate(Array.isArray(date) ? date : [date]));
      fetchPayPeriods({
        status: statusState,
        actionRequired: actionRequiredState,
        valueDate: date,
      });
    }
  };

  const handleActionRequiredChange = ({ value }: OnChangeParams) => {
    dispatch(changeActionRequired(value));
    fetchPayPeriods({
      status: statusState, actionRequired: actionRequiredState, valueDate: valueDateState,
    });
  };

  const handleStatusChange = ({ value }: OnChangeParams) => {
    dispatch(setStatus(value));
    fetchPayPeriods({
      status: value, actionRequired: actionRequiredState, valueDate: valueDateState,
    });
  };

  useEffect(() => {
    if (JSON.stringify(prevDate.current) !== JSON.stringify(valueDateState)) {
      prevDate.current = valueDateState;
    }
  }, [valueDateState]);

  return (
    <Grid align={ALIGNMENT.start} gridMargins={[20, 50, 65]}>
      <CellFormControl
        cellSpan={[12, 3, 3]}
        label={t('dashboard:dashboard.payPeriodFilters.actionRequired')}
      >
        <Select
          id="dashboard-pay-period-action-required-filter"
          size={SIZE.compact}
          placeholder={t('common:all')}
          type="select"
          options={actionRequiredOptions}
          clearable={false}
          labelKey="name"
          valueKey="id"
          onChange={handleActionRequiredChange}
          value={actionRequiredState}
        />
      </CellFormControl>
      <CellFormControl
        cellSpan={[12, 3, 3]}
        label={t('dashboard:dashboard.payPeriodFilters.payPeriodStatus')}
      >
        <Select
          id="dashboard-pay-period-status-filter"
          size={SIZE.compact}
          placeholder={t('common:all')}
          type="select"
          clearable={false}
          options={statusOptions}
          labelKey="name"
          valueKey="id"
          onChange={handleStatusChange}
          value={statusState}
        />
      </CellFormControl>
      <CellFormControl
        cellSpan={[12, 3, 3]}
        label={t('dashboard:dashboard.payPeriodFilters.dateRange')}
      >
        <DatePicker
          clearable
          value={valueDateState}
          onChange={handleDateChange}
          formatString={datePickerFormat}
          placeholder={`${displayFormat} - ${displayFormat}`}
          range
          size={SIZE.compact}
          overrides={{
            InputWrapper: {
              props: {
                'data-testid': 'Dashboard-PayPeriodStatusFilters-dateRange',
              },
            },
            Input: {
              props: {
                id: 'Dashboard-PayPeriodStatusFilters-dateRange',
                'data-testid': 'Dashboard-PayPeriodStatusFilters-dateRange',
              },
            },
          }}
        />
      </CellFormControl>
    </Grid>
  );
};

export default memo(PayPeriodStatusFilters);
