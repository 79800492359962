import { OrganizationIDType } from 'types/OrganizationTypes';
import request from './request';

export const fetchPayPeriodsWithActionsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  pageNumber?: number,
  pageSize?: number,
  from?: string,
  to?: string,
  statuses?: string,
  actionRequired?: boolean,
) => {
  const queryParams = new URLSearchParams({});

  from && queryParams.set('from', from);
  to && queryParams.set('to', to);
  pageNumber && queryParams.set('pageNumber', pageNumber.toString());
  pageSize && queryParams.set('pageSize', pageSize.toString());
  statuses && queryParams.set('statuses', statuses);
  actionRequired !== undefined && queryParams.set('actionRequired', actionRequired.toString());

  return request({
    token,
    url: `/organizations/${organizationID}/payroll-periods-with-actions?${queryParams}`,
  });
};

export default null;
